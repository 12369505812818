import React from 'react';
import './StudentBedroomCard.css'

function StudentBedroomCard({person, onClick}) {
  let imgPath;
  if (person.studentGender === 'Male') {
    const maleImgPaths = [
      "/assets/img/1.png",
      "/assets/img/3.png",
      "/assets/img/6.png",
      "/assets/img/8.png"
    ];
      imgPath = process.env.PUBLIC_URL + maleImgPaths[Math.floor(Math.random() * maleImgPaths.length)];
  } else {
    const femaleImgPaths = [
      "/assets/img/2.png",
      "/assets/img/4.png",
      "/assets/img/5.png",
      "/assets/img/7.png"];
    imgPath = process.env.PUBLIC_URL + femaleImgPaths[Math.floor(Math.random() * femaleImgPaths.length)];
  }
  
  return(
    <div className="tc my-card dib br3 pa3 ma2 grow bw2 shadow-5" onClick={onClick}>
      <img className="br-100 h3 w3 dib" alt={person.id} src={imgPath} />
      <div>
        <p>{person.studentName} {person.studentSurname}</p>
        <p>Campus: {person.stay_campus}</p>
        <p>Age: {person.age}</p>
        {person.roomRequirements && person.roomRequirements.toLowerCase() !== "na" && (
          <p style={{ fontStyle: 'italic' }}>{person.roomRequirements}</p>
        )}
      </div>
    </div>
  );
}

export default StudentBedroomCard;
