import React, { useState, useCallback, useEffect } from 'react';
import { DndContext } from '@dnd-kit/core';
import { SERVER_URL } from '../constants.js';
import { WEEKS } from '../constants.js';

import Draggable from './Draggable.jsx';
import Droppable from './Droppable.jsx';
import StudentClassroomCard from './StudentClassroomCard.js';

function Classrooms() {
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [databaseState, setDatabaseState] = useState({ classrooms: [], students: [] });
    const [displayState, setDisplayState] = useState({ classrooms: [], students: [] });
    const [selectedWeek, setSelectedWeek] = useState(WEEKS[0]);
    const [selectedCampus, setSelectedCampus] = useState('');
    const [selectedEnglishLevel, setSelectedEnglishLevel] = useState('');
    const [selectedAge, setSelectedAge] = useState('');
    const [selectedNationality, setSelectedNationality] = useState('');
    const [campuses, setCampuses] = useState([]);
    const [englishLevels, setEnglishLevels] = useState([]);
    const [ages, setAges] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [studentsFetched, setStudentsFetched] = useState(false);

    const fetchStudents = useCallback((startDate, endDate) => {
        const token = sessionStorage.getItem('bearer');
        fetch(`${SERVER_URL}api/rolebyweek/${startDate}/${endDate}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => response.ok ? response.json() : [])
        .then((data) => {
            setStudents(data);
            setFilteredStudents(data);
            setDatabaseState(prevState => ({
                ...prevState,
                students: data
            }));
            setDisplayState(prevState => ({
                ...prevState,
                students: data
            }));
            setStudentsFetched(true);  // Indicate that students have been fetched
        })
        .catch((err) => console.error(err));
    }, []);

    const filterGroupsByWeekAndCampus = useCallback((groups, startDate, endDate, campus) => {
        return groups.filter(group =>
            (!campus || group.campus === campus) &&
            group.groupDates.some(date => {
                const groupDate = new Date(date);
                return groupDate >= new Date(startDate) && groupDate <= new Date(endDate);
            })
        );
    }, []);

    const fetchGroups = useCallback(async(startDate, endDate, campus, studentData) => {
        const token = sessionStorage.getItem('bearer');
        try {
            const response = await fetch(`${SERVER_URL}api/campgroups/type/CLASS`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const allGroups = await response.json();

            // Debug log
            console.log("All fetched groups:", allGroups);

            const filteredGroups = filterGroupsByWeekAndCampus(allGroups, startDate, endDate, campus);

            // Debug log
            console.log("Filtered groups by campus and date:", filteredGroups);
            
            const groupsWithRelevantStudents = filteredGroups.map(group => {
                const relevantStudentIds = group.studentIds.filter(id => 
                    studentData.some(student => student.studentId === id)
                );
    
                // Debug log
                console.log(`Filtered student IDs for group ${group.id}:`, relevantStudentIds);
    
                return {
                    ...group,
                    studentIds: relevantStudentIds,
                    students: relevantStudentIds.map(id => studentData.find(student => student.studentId === id)).filter(student => student)
                };
            });
            
            setDatabaseState(prevState => ({
                ...prevState,
                classrooms: groupsWithRelevantStudents
            }));
            setDisplayState(prevState => ({
                ...prevState,
                classrooms: groupsWithRelevantStudents
            }));
    
            // Debug log
            console.log("Updated groups with relevant students:", groupsWithRelevantStudents);
        } catch (err) {
            console.error('failed to fetch groups: ', err);
        }
    }, [filterGroupsByWeekAndCampus]);

    useEffect(() => {
        fetchStudents(selectedWeek.startDate, selectedWeek.endDate);
    }, [fetchStudents, selectedWeek]);

    useEffect(() => {
        if (studentsFetched) {
            fetchGroups(selectedWeek.startDate, selectedWeek.endDate, selectedCampus, students);
        }
    }, [studentsFetched, selectedWeek, selectedCampus, fetchGroups, students]);

    const handleWeekChange = (event) => {
        const selectedWeek = WEEKS.find(week => week.week === event.target.value);
        setSelectedWeek(selectedWeek);
        setStudentsFetched(false);  // Reset the fetched state when week changes
    };

    const handleCampusChange = (event) => {
        setSelectedCampus(event.target.value);
    };

    const handleEnglishLevelChange = (event) => {
        setSelectedEnglishLevel(event.target.value);
    };

    const handleAgeChange = (event) => {
        setSelectedAge(event.target.value);
    };

    const handleNationalityChange = (event) => {
        setSelectedNationality(event.target.value);
    };

    const filterStudents = useCallback(() => {
        let filtered = students;

        if (selectedCampus) {
            filtered = filtered.filter(student => student.stay_campus === selectedCampus);
        }

        if (selectedEnglishLevel) {
            filtered = filtered.filter(student => student.englishLevel === selectedEnglishLevel);
        }

        if (selectedAge) {
            filtered = filtered.filter(student => student.age === parseInt(selectedAge, 10));
        }

        if (selectedNationality) {
            filtered = filtered.filter(student => student.studentNationality === selectedNationality);
        }
        setFilteredStudents(filtered);
    }, [students, selectedCampus, selectedEnglishLevel, selectedAge, selectedNationality]);

    useEffect(() => {
        filterStudents();
    }, [selectedCampus, selectedEnglishLevel, filterStudents]);

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (!over) return;

        const draggedId = parseInt(active.id);
        const fromClassroom = displayState.classrooms.find(room => room.students.some(s => s && s.studentId === draggedId));
        const toClassroom = over.id === "unassigned" ? null : displayState.classrooms.find(room => room.id === over.id);

        if (fromClassroom === toClassroom) {
            return;
        }

        let updatedClassrooms = [...displayState.classrooms];

        if (fromClassroom) {
            updatedClassrooms = updatedClassrooms.map(room => {
                if (room.id === fromClassroom.id) {
                    return { ...room, students: room.students.filter(s => s && s.studentId !== draggedId) };
                }
                return room;
            });
        }

        if (toClassroom) {
            updatedClassrooms = updatedClassrooms.map(room => {
                if (room.id === toClassroom.id) {
                    const student = students.find(s => s && s.studentId === draggedId);
                    return { ...room, students: [...room.students, student] };
                }
                return room;
            });
        }

        setDisplayState(prevState => ({
            ...prevState,
            classrooms: updatedClassrooms
        }));
    };

    const updateRoomStudents = () => {
        const token = sessionStorage.getItem('bearer');
        const addRequests = [];
        const deleteRequests = [];

        displayState.classrooms.forEach(classroom => {
            const databaseClassroom = databaseState.classrooms.find(ic => ic.id === classroom.id);
            const databaseStudentIds = databaseClassroom.studentIds;
            const currentStudentIds = classroom.students.map(s => s.studentId);

            const studentsToAdd = currentStudentIds.filter(id => !databaseStudentIds.includes(id));
            const studentsToRemove = databaseStudentIds.filter(id => !currentStudentIds.includes(id));

            studentsToAdd.forEach(studentId => {
                addRequests.push(fetch(`${SERVER_URL}api/campgroup/${classroom.id}/student/${studentId}`, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }));
            });

            studentsToRemove.forEach(studentId => {
                deleteRequests.push(fetch(`${SERVER_URL}api/campgroup/${classroom.id}/student/${studentId}`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }));
            });
        });

        Promise.all([...addRequests, ...deleteRequests])
            .then(() => {
                console.log('All operations completed');
                fetchStudents(selectedWeek.startDate, selectedWeek.endDate);
                fetchGroups(selectedWeek.startDate, selectedWeek.endDate, selectedCampus, students);
            })
            .catch(err => console.error('Error with updating students:', err));
    };

    useEffect(() => {
        const newCampuses = Array.from(new Set(students.map(student => student.stay_campus)));
        const englishLevels = Array.from(new Set(students.map(student => student.englishLevel)));
        const newAges = Array.from(new Set(students.map(student => student.age)));
        const newNationalities = Array.from(new Set(students.map(student => student.studentNationality)));

        setCampuses(newCampuses);
        setEnglishLevels(englishLevels);
        setAges(newAges);
        setNationalities(newNationalities)

        if (newCampuses.length > 0 && selectedCampus === '') {
            setSelectedCampus(newCampuses[0]);
        }
    }, [students]);

    return (
        <DndContext onDragEnd={handleDragEnd}>
            <div className="classrooms-container">
                <div className="filter-container">
                    <div className="filter">
                        <label htmlFor="week-select">Select Week: </label>
                        <select id="week-select" value={selectedWeek.week} onChange={handleWeekChange}>
                            {WEEKS.map(week => (
                                <option key={week.week} value={week.week}>
                                    {week.week}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="filter">
                        <label htmlFor="campus-select">Select Campus: </label>
                        <select id="campus-select" value={selectedCampus} onChange={handleCampusChange}>
                            <option value="">All Campuses</option>
                            {campuses.map(campus => (
                                <option key={campus} value={campus}>
                                    {campus}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="filter">
                        <label htmlFor="english-level-select">Select English Level: </label>
                        <select id="english-level-select" value={selectedEnglishLevel} onChange={handleEnglishLevelChange}>
                            <option value="">All Levels</option>
                            {englishLevels.map(level => (
                                <option key={level} value={level}>
                                    {level}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="filter">
                        <label htmlFor="age-select">Select age: </label>
                        <select id="age-select" value={selectedAge} onChange={handleAgeChange}>
                            <option value="">All Ages</option>
                            {ages.map(age => (
                                <option key={age} value={age}>
                                    {age}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="filter">
                        <label htmlFor="nationality-select">Select nationality: </label>
                        <select id="nationality-select" value={selectedNationality} onChange={handleNationalityChange}>
                            <option value="">All Nationalities</option>
                            {nationalities.map(nationality => (
                                <option key={nationality} value={nationality}>
                                    {nationality}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <Droppable key="unassigned" id="unassigned" className="classroom unassigned-students">
                    <div className="droppable-area">
                        <h3>Unassigned Students</h3>
                        {filteredStudents.filter(student =>
                            !displayState.classrooms.some(room => room.students.some(s => s && s.studentId === student.studentId))
                        ).map(student => (
                            <Draggable key={student.studentId} id={student.studentId.toString()}>
                                <StudentClassroomCard person={student} />
                            </Draggable>
                        ))}
                    </div>
                </Droppable>

                <div className="spacer"></div>
                <div className="button-container">
                    <button onClick={updateRoomStudents}>Commit Changes to Database</button>
                </div>
                <div className="spacer"></div>

                {displayState.classrooms.map((classroom) => (
                    <Droppable key={classroom.id} id={classroom.id} className="classroom">
                        <div className="droppable-area">
                            <h4>{classroom.groupName}</h4>
                            <h5>Leader: {classroom.leader ? classroom.leader.adultName : 'No leader assigned'}</h5>
                            {classroom.students.map(student => (
                                <Draggable key={student.studentId} id={student.studentId.toString()}>
                                    <StudentClassroomCard person={student} />
                                </Draggable>
                            ))}
                            {classroom.students.length === 0 && <p>No students assigned</p>}
                        </div>
                    </Droppable>
                ))}
            </div>
        </DndContext>
    );
}

export default Classrooms;
