import React, { useState, useCallback, useEffect } from 'react';
import { SERVER_URL, WEEKS } from '../constants.js';
import { saveAs } from 'file-saver';

function WeeklyLists() {
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [selectedWeek, setSelectedWeek] = useState(WEEKS[0]);
    const [selectedCampus, setSelectedCampus] = useState('');
    const [selectedNationality, setSelectedNationality] = useState('');
    const [selectedAge, setSelectedAge] = useState('');
    const [selectedEnglishLevel, setSelectedEnglishLevel] = useState('');
    const [selectedAllergy, setSelectedAllergy] = useState('');
    const [campuses, setCampuses] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [ages, setAges] = useState([]);
    const [englishLevels, setEnglishLevels] = useState([]);
    const [classlists, setClasslists] = useState([]);
    const [filteredClasslists, setFilteredClasslists] = useState([]);
    const [isStudentListCollapsed, setIsStudentListCollapsed] = useState(true);
    const [isClassListCollapsed, setIsClassListCollapsed] = useState(true);
    const [classes, setClasses] = useState([]);
    const [changes, setChanges] = useState([]);

    useEffect(() => {
        console.log("Changes:", changes);
    }, [changes]);

    const fetchStudents = useCallback((startDate, endDate) => {
        const token = sessionStorage.getItem('bearer');
        fetch(`${SERVER_URL}api/rolebyweek/${startDate}/${endDate}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => response.ok ? response.json() : [])
        .then((data) => {
            data.sort((a, b) => a.studentSurname.localeCompare(b.studentSurname));
            console.log("Fetched students:", data.map(student => student.studentId));
            setStudents(data);
        })
        .catch((err) => console.error(err));
    }, []);

    const fetchClasslists = useCallback((startDate, endDate) => {
        const token = sessionStorage.getItem('bearer');
        fetch(`${SERVER_URL}api/class_lists/${startDate}/${endDate}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => response.ok ? response.json() : [])
        .then((data) => {
            console.log("Fetched classlists:", data.map(student => student.studentId));
            setClasslists(data);
        })
        .catch((err) => console.error(err));
    }, []);

    const fetchGroups = useCallback(async(startDate, endDate, campus) => {
        const token = sessionStorage.getItem('bearer');
        try {
            const response = await fetch(`${SERVER_URL}api/campgroups/type/CLASS`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const allGroups = await response.json();
            const filteredGroups = filterGroupsByWeekAndCampus(allGroups, startDate, endDate, campus);
            setClasses(filteredGroups);
        } catch (err) {
            console.error('failed to fetch classes: ', err);
        }
    }, []);

    const filterGroupsByWeekAndCampus = useCallback((groups, startDate, endDate, campus) => {
        return groups.filter(group =>
            (!campus || group.campus === campus) &&
            group.groupDates.some(date => {
                const groupDate = new Date(date);
                return groupDate >= new Date(startDate) && groupDate <= new Date(endDate);
            })
        );
    }, []);

    const filterStudents = useCallback(() => {
        let filtered = students;
        let filteredClasslists = classlists;

        if (selectedCampus) {
            filtered = filtered.filter(student => student.stay_campus === selectedCampus);
            filteredClasslists = filteredClasslists.filter(student => student.campus === selectedCampus);
        }

        if (selectedNationality) {
            filtered = filtered.filter(student => student.studentNationality === selectedNationality);
            filteredClasslists = filteredClasslists.filter(student => student.student_nationality === selectedNationality);
        }

        if (selectedAllergy) {
            filtered = filtered.filter(student => 
                (selectedAllergy === 'withAllergies' && student.allergies && !['NA', 'Na', 'na', 'N/A'].includes(student.allergies.trim())) ||
                (selectedAllergy === 'withoutAllergies' && (!student.allergies || ['NA', 'Na', 'na', 'N/A'].includes(student.allergies.trim())))
            );
        }

        if (selectedAge) {
            filteredClasslists = filteredClasslists.filter(student => student.student_age === parseInt(selectedAge, 10));
            
        }
        
        if (selectedEnglishLevel) {
            filteredClasslists = filteredClasslists.filter(student => student.english_level === selectedEnglishLevel); 
        }

        const uniqueFiltered = filtered.filter((student, index, self) =>
            index === self.findIndex((s) => (
                s.studentId === student.studentId
            ))
        );

        const uniqueFilteredClasslists = filteredClasslists.filter((student, index, self) =>
            index === self.findIndex((s) => (
                s.studentId === student.studentId
            ))
        );

        const duplicateCheck = (array) => {
            const seen = new Set();
            array.forEach(student => {
                if (seen.has(student.studentId)) {
                    console.warn(`Duplicate found: ${student.studentId}`);
                } else {
                    seen.add(student.studentId);
                }
            });
        };

        console.log("Filtered students:", uniqueFiltered.map(student => student.studentId));
        duplicateCheck(uniqueFiltered);

        console.log("Filtered classlists:", uniqueFilteredClasslists.map(student => student.studentId));
        duplicateCheck(uniqueFilteredClasslists);

        setFilteredStudents(uniqueFiltered);
        setFilteredClasslists(uniqueFilteredClasslists);
    }, [students, selectedCampus, selectedNationality, selectedAllergy, classlists, selectedAge, selectedEnglishLevel]);

    useEffect(() => {
        setStudents([]);
        setClasslists([]);
        setFilteredStudents([]);
        setFilteredClasslists([]);
        fetchStudents(selectedWeek.startDate, selectedWeek.endDate);
        fetchClasslists(selectedWeek.startDate, selectedWeek.endDate);
        fetchGroups(selectedWeek.startDate, selectedWeek.endDate, selectedCampus);
    }, [fetchStudents, fetchClasslists, fetchGroups, selectedWeek, selectedCampus]);

    useEffect(() => {
        filterStudents();
    }, [students, classlists, selectedCampus, selectedNationality, selectedAllergy, selectedAge, selectedEnglishLevel, filterStudents]);

    useEffect(() => {
        const newCampuses = Array.from(new Set(students.map(student => student.stay_campus)));
        const newNationalities = Array.from(new Set(students.map(student => student.studentNationality)));

        setCampuses(newCampuses);
        setNationalities(newNationalities);
    }, [students]);

    useEffect(() => {
        const newAges = Array.from(new Set(classlists.map(student => student.student_age)));
        const newEnglishLevels = Array.from(new Set(classlists.map(student => student.english_level)));

        setAges(newAges);
        setEnglishLevels(newEnglishLevels);
    }, [classlists]);

    const handleWeekChange = (event) => {
        const selectedWeek = WEEKS.find(week => week.week === event.target.value);
        setSelectedWeek(selectedWeek);
    };

    const handleCampusChange = (event) => {
        setSelectedCampus(event.target.value);
    };

    const handleNationalityChange = (event) => {
        setSelectedNationality(event.target.value);
    };

    const handleAgeChange = (event) => {
        setSelectedAge(event.target.value);
    };

    const handleAllergyChange = (event) => {
        setSelectedAllergy(event.target.value);
    };

    const handleEnglishLevelChange = (event) => {
        setSelectedEnglishLevel(event.target.value);
    };

    const handleResidentExportCSV = (dataToExport, headerRow, fileName) => {
        const csvData = dataToExport.map(
            ({ studentId, studentName, studentSurname, studentNationality, stay_campus, stay_start, stay_end, allergies, roomRequirements, Notes }) =>
                `${studentId},${studentName} ${studentSurname},${studentNationality},${stay_campus},${stay_start},${stay_end},${allergies},${roomRequirements},${Notes}`
        );

        const csvContent = headerRow + '\n' + csvData.join('\n');
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        saveAs(blob, fileName);
    };

    const handleClasslistExportCSV = (dataToExport, headerRow, fileName) => {
        const csvData = dataToExport.map(
            ({ teacher, student_surname, student_name, campus, english_level, student_age, student_nationality, studentId, group_name }) =>
                `${teacher},${student_surname},${student_name},${campus},${english_level},${student_age},${student_nationality},${studentId},${group_name}`
        );

        const csvContent = headerRow + '\n' + csvData.join('\n');
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        saveAs(blob, fileName);
    };

    const toggleStudentList = () => {
        setIsStudentListCollapsed(!isStudentListCollapsed);
    };

    const toggleClassList = () => {
        setIsClassListCollapsed(!isClassListCollapsed);
    };

    const handleClassChange = (studentId, field, value) => {
        setFilteredClasslists(prevClasslists =>
            prevClasslists.map(student => {
                if (student.studentId === studentId) {
                    const updatedStudent = { ...student, [field]: value };
                    let changeRecord = { studentId, previousGroup: student.group_id, newGroup: null };

                    if (field === 'group_name') {
                        const selectedGroup = classes.find(cls => cls.groupName === value);
                        if (selectedGroup && selectedGroup.leader) {
                            updatedStudent.adult_full_name = `${selectedGroup.leader.adultName} ${selectedGroup.leader.adultSurname}`;
                            changeRecord.newGroup = selectedGroup.id;
                            updatedStudent.group_id = selectedGroup.id;
                        }
                    } else if (field === 'adult_full_name') {
                        const selectedGroup = classes.find(cls => cls.leader && `${cls.leader.adultName} ${cls.leader.adultSurname}` === value);
                        if (selectedGroup) {
                            updatedStudent.group_name = selectedGroup.groupName;
                            changeRecord.newGroup = selectedGroup.id;
                            updatedStudent.group_id = selectedGroup.id;
                        }
                    }

                    setChanges(prevChanges => {
                        const existingChangeIndex = prevChanges.findIndex(change => change.studentId === studentId);
                        if (existingChangeIndex > -1) {
                            const updatedChanges = [...prevChanges];
                            updatedChanges[existingChangeIndex] = changeRecord;
                            return updatedChanges;
                        } else {
                            return [...prevChanges, changeRecord];
                        }
                    });

                    return updatedStudent;
                }
                return student;
            })
        );
    };

    const handleSaveChanges = async () => {
        const token = sessionStorage.getItem('bearer');
        const requests = changes.flatMap(change => {
            const { studentId, previousGroup, newGroup } = change;
            const requests = [];
            if (previousGroup) {
                const removeUrl = `${SERVER_URL}api/campgroup/${previousGroup}/student/${studentId}`;
                requests.push(fetch(removeUrl, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                }));
            }
            const addUrl = `${SERVER_URL}api/campgroup/${newGroup}/student/${studentId}`;
            requests.push(fetch(addUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }));
            return requests;
        });

        try {
            await Promise.all(requests);
            alert('Changes saved successfully.');
            setChanges([]);
        } catch (err) {
            console.error('Failed to save changes:', err);
            alert('Failed to save changes.');
        }
    };

    return (
        <section className="garamond">
            <div className="pa2"></div>
            <div className="classrooms-container">
                <div className="filter-container" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                    <div className="filter">
                        <label htmlFor="week-select">Select Week: </label>
                        <select id="week-select" value={selectedWeek.week} onChange={handleWeekChange}>
                            {WEEKS.map(week => (
                                <option key={week.week} value={week.week}>
                                    {week.week}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="filter">
                        <label htmlFor="campus-select">Select Campus: </label>
                        <select id="campus-select" value={selectedCampus} onChange={handleCampusChange}>
                            <option value="">All Campuses</option>
                            {campuses.map(campus => (
                                <option key={campus} value={campus}>
                                    {campus}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="filter">
                        <label htmlFor="nationality-select">Select Nationality: </label>
                        <select id="nationality-select" value={selectedNationality} onChange={handleNationalityChange}>
                            <option value="">All Nationalities</option>
                            {nationalities.map(nationality => (
                                <option key={nationality} value={nationality}>
                                    {nationality}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <button onClick={toggleStudentList} type="button">
                    {isStudentListCollapsed ? 'Show Residents' : 'Hide Residents'}
                </button>
                <button onClick={toggleClassList} type="button">
                    {isClassListCollapsed ? 'Show Class List' : 'Hide Class List'}
                </button>

                {!isStudentListCollapsed && (
                    <div>
                        <div className="filter">
                            <label htmlFor="allergy-select">Allergies: </label>
                            <select id="allergy-select" value={selectedAllergy} onChange={handleAllergyChange}>
                                <option value="">All</option>
                                <option value="withAllergies">Allergies</option>
                            </select>
                        </div>
                        <table style={{ width: '80%', textAlign: 'left', margin: '50px auto', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Nationality</th>
                                    <th>Campus</th>
                                    <th>Arrival</th>
                                    <th>Departure</th>
                                    <th>Allergies</th>
                                    <th>Room Requirements</th>
                                    <th>Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredStudents.map((resident) => (
                                    <tr key={`${resident.studentId}`}>
                                        <td>{resident.studentId}</td>
                                        <td>{resident.studentName} {resident.studentSurname}</td>
                                        <td>{resident.studentNationality}</td>
                                        <td>{resident.stay_campus}</td>
                                        <td>{resident.stay_start}</td>
                                        <td>{resident.stay_end}</td>
                                        <td>{resident.allergies}</td>
                                        <td>{resident.roomRequirements}</td>
                                        <td>{resident.Notes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {!isClassListCollapsed && (
                    <div>
                        <div className="filter">
                        <label htmlFor="age-select">Select Age: </label>
                        <select id="age-select" value={selectedAge} onChange={handleAgeChange}>
                            <option value="">All Ages</option>
                            {ages.map(age => (
                                <option key={age} value={age}>
                                    {age}
                                </option>
                            ))}
                        </select>
                        </div>
                        <div className="filter">
                        <label htmlFor="level-select">Select Level: </label>
                        <select id="level-select" value={selectedEnglishLevel} onChange={handleEnglishLevelChange}>
                            <option value="">All Levels</option>
                            {englishLevels.map(level => (
                                <option key={level} value={level}>
                                    {level}
                                </option>
                            ))}
                        </select>
                        </div>
                        <table style={{ width: '80%', textAlign: 'left', margin: '50px auto', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th>Teacher</th>
                                    <th>Surname</th>
                                    <th>Forename</th>
                                    <th>Campus</th>
                                    <th>English Level</th>
                                    <th>Age</th>
                                    <th>Nationality</th>
                                    <th>StudentId</th>
                                    <th>Class Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredClasslists.map((student) => (
                                    <tr key={`${student.studentId}`}>
                                        <td>
                                            <select
                                                value={student.adult_full_name || ''}
                                                onChange={(e) => handleClassChange(student.studentId, 'adult_full_name', e.target.value)}
                                            >
                                                <option value={student.adult_full_name || ''}>{student.adult_full_name || 'No Leader'}</option>
                                                {classes.map(cls => cls.leader && (
                                                    <option key={cls.id} value={`${cls.leader.adultName} ${cls.leader.adultSurname}`}>
                                                        {`${cls.leader.adultName} ${cls.leader.adultSurname}`}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                        <td>{student.student_surname}</td>
                                        <td>{student.student_name}</td>
                                        <td>{student.campus}</td>
                                        <td>{student.english_level}</td>
                                        <td>{student.student_age}</td>
                                        <td>{student.student_nationality}</td>
                                        <td>{student.studentId}</td>
                                        <td>
                                            <select
                                                value={student.group_name}
                                                onChange={(e) => handleClassChange(student.studentId, 'group_name', e.target.value)}
                                            >
                                                <option value={student.group_name}>{student.group_name || 'No Group'}</option>
                                                {classes.map(cls => (
                                                    <option key={cls.id} value={cls.groupName}>
                                                        {cls.groupName}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button onClick={handleSaveChanges} type="button">
                            Save Changes
                        </button>
                    </div>
                )}

                <div>
                    <button onClick={() => handleResidentExportCSV(
                        filteredStudents,
                        "Id, Name, Nationality, Campus, Arrival, Departure, Allergies, Room Requirements, Notes",
                        "residents.csv"
                    )} type="button">
                        Save Students CSV
                    </button>
                    <button onClick={() => handleClasslistExportCSV(
                        filteredClasslists,
                        "Teacher, Surname, Forename, Campus, English Level, Age, Nationality, StudentId, Class Name",
                        "classlists.csv"
                    )} type="button">
                        Save Class List CSV
                    </button>
                </div>
            </div>
        </section>
    );
}

export default WeeklyLists;
