import React from 'react';
import './Card.css'; // Import the CSS file

function Card({ person, onClick }) {
  let imgPath;
  if (person.studentGender && person.studentGender.toLowerCase() === 'male') {
    const maleImgPaths = [
      "/assets/img/1.png",
      "/assets/img/3.png",
      "/assets/img/6.png",
      "/assets/img/8.png"
    ];
    imgPath = process.env.PUBLIC_URL + maleImgPaths[Math.floor(Math.random() * maleImgPaths.length)];
  } else {
    const femaleImgPaths = [
      "/assets/img/2.png",
      "/assets/img/4.png",
      "/assets/img/5.png",
      "/assets/img/7.png"
    ];
    imgPath = process.env.PUBLIC_URL + femaleImgPaths[Math.floor(Math.random() * femaleImgPaths.length)];
  }

  return (
    <div className="card-container" onClick={onClick}>
      <img className="card-image" alt={person.id} src={imgPath} />
      <div className="card-details">
        <h2>{person.studentName}</h2>
        <p>{person.studentSurname}</p>
        <p>{person.id}</p>
        <p>{person.studentNationality}</p>
      </div>
    </div>
  );
}

export default Card;
