import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { SERVER_URL, CAMPUSES } from '../constants.js';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { saveAs } from 'file-saver';
import './Activities.css'; // Make sure this path is correct

function Activities() {
    const [activities, setActivities] = useState([]);
    const [afternoonGroups, setAfternoonGroups] = useState([]);
    const [excursionGroups, setExcursionGroups] = useState([]);
    const [changes, setChanges] = useState([]);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [formattedDate, setFormattedDate] = useState(selectedDate.toISOString().split('T')[0]);
    const [selectedCampus, setSelectedCampus] = useState(CAMPUSES[0].value);

    const prepopulateActivities = useCallback((activities, afternoonGroups, excursionGroups) => {
        return activities.map(activity => {
            const group = afternoonGroups.find(g => g.studentIds.includes(activity.studentId)) ||
                          excursionGroups.find(g => g.studentIds.includes(activity.studentId));
            if (group) {
                activity.group_name = group.groupName;
                if (group.leader) {
                    activity.adult_full_name = `${group.leader.adultName} ${group.leader.adultSurname}`;
                } else {
                    activity.adult_full_name = '';
                }
                activity.group_id = group.id;
            } else {
                activity.group_name = '';
                activity.adult_full_name = '';
                activity.group_id = null;
            }
            return activity;
        });
    }, []);

    const fetchActivities = useCallback((formattedDate, campus) => {
        const token = sessionStorage.getItem('bearer');
        fetch(`${SERVER_URL}api/whoisdoing/${formattedDate}/${campus}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                if (response.status === 204) {
                    return [];
                } else if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to fetch activities');
                }
            })
            .then((data) => {
                sessionStorage.setItem('activities', JSON.stringify(data));
                setActivities(data);
            })
            .catch((err) => console.error(err));
    }, []);

    const fetchGroups = useCallback(async (campus, type, setGroups) => {
        const token = sessionStorage.getItem('bearer');
        try {
            const response = await fetch(`${SERVER_URL}api/campgroups/type/${type}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const allGroups = await response.json().catch(() => []);
            const filteredGroups = allGroups.filter(group =>
                group.campus === campus &&
                group.groupDates.includes(formattedDate)
            );
            setGroups(filteredGroups.length > 0 ? filteredGroups : []);
        } catch (err) {
            console.error(`Failed to fetch ${type} groups: `, err);
            setGroups([]);
        }
    }, [formattedDate]);

    const refreshData = useCallback(() => {
        fetchGroups(selectedCampus, 'AFTERNOON', setAfternoonGroups);
        fetchGroups(selectedCampus, 'EXCURSION', setExcursionGroups);
        fetchActivities(formattedDate, selectedCampus);
    }, [selectedCampus, fetchGroups, fetchActivities, formattedDate]);

    useEffect(() => {
        refreshData();
    }, [selectedCampus, selectedDate, refreshData]);

    useEffect(() => {
        if (activities.length > 0) {
            setActivities(prevActivities => {
                if (changes.length === 0) {
                    return prepopulateActivities(activities, afternoonGroups, excursionGroups);
                }
                return prevActivities;
            });
        }
    }, [activities, afternoonGroups, excursionGroups, changes.length, prepopulateActivities]);

    const handleDateChange = (date) => {
        setActivities([]);  // Clear activities
        setAfternoonGroups([]);  // Clear afternoon groups
        setExcursionGroups([]);  // Clear excursion groups
        const newFormattedDate = date.toISOString().split('T')[0];
        setSelectedDate(date);
        setFormattedDate(newFormattedDate);
    };

    const handleCampusChange = (event) => {
        setActivities([]);  // Clear activities
        setAfternoonGroups([]);  // Clear afternoon groups
        setExcursionGroups([]);  // Clear excursion groups
        setSelectedCampus(event.target.value);
    };

    const changeDate = (increment) => {
        const newDate = dayjs(selectedDate).add(increment, 'day');
        setActivities([]);  // Clear activities
        setAfternoonGroups([]);  // Clear afternoon groups
        setExcursionGroups([]);  // Clear excursion groups
        const newFormattedDate = newDate.toISOString().split('T')[0];
        setSelectedDate(newDate);
        setFormattedDate(newFormattedDate);
    };

    const handleGroupChange = (studentId, field, value, groupType) => {
        setActivities(prevActivities =>
            prevActivities.map(activity => {
                if (activity.studentId === studentId) {
                    const updatedActivity = { ...activity, [field]: value };
                    let changeRecord = { studentId, previousGroup: activity.group_id, newGroup: null };

                    const selectedGroup = (groupType === 'AFTERNOON' ? afternoonGroups : excursionGroups).find(group => {
                        if (field === 'group_name') {
                            return group.groupName === value;
                        }
                        if (field === 'adult_full_name' && group.leader) {
                            return `${group.leader.adultName} ${group.leader.adultSurname}` === value;
                        }
                        return false;
                    });

                    if (selectedGroup) {
                        updatedActivity.group_name = selectedGroup.groupName;
                        updatedActivity.adult_full_name = selectedGroup.leader ? `${selectedGroup.leader.adultName} ${selectedGroup.leader.adultSurname}` : '';
                        updatedActivity.group_id = selectedGroup.id;
                        changeRecord.newGroup = selectedGroup.id;
                    } else {
                        updatedActivity.group_name = '';
                        updatedActivity.adult_full_name = '';
                        updatedActivity.group_id = null;
                    }

                    setChanges(prevChanges => {
                        const existingChangeIndex = prevChanges.findIndex(change => change.studentId === studentId);
                        if (existingChangeIndex > -1) {
                            const updatedChanges = [...prevChanges];
                            updatedChanges[existingChangeIndex] = changeRecord;
                            return updatedChanges;
                        } else {
                            return [...prevChanges, changeRecord];
                        }
                    });

                    return updatedActivity;
                }
                return activity;
            })
        );
    };

    const handleSaveChanges = async () => {
        const token = sessionStorage.getItem('bearer');
        const requests = changes.flatMap(change => {
            const { studentId, previousGroup, newGroup } = change;
            const requests = [];
            if (previousGroup) {
                const removeUrl = `${SERVER_URL}api/campgroup/${previousGroup}/student/${studentId}`;
                requests.push(fetch(removeUrl, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                }));
            }
            const addUrl = `${SERVER_URL}api/campgroup/${newGroup}/student/${studentId}`;
            requests.push(fetch(addUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }));
            return requests;
        });

        try {
            await Promise.all(requests);
            refreshData();
            alert('Changes saved successfully.');
            setChanges([]);
        } catch (err) {
            console.error('Failed to save changes:', err);
            alert('Failed to save changes.');
        }

    };

    const groupActivitiesByProductName = (activities) => {
        return activities.reduce((groups, activity) => {
            const { productName } = activity;
            if (!groups[productName]) {
                groups[productName] = [];
            }
            groups[productName].push(activity);
            return groups;
        }, {});
    };

    const groupedActivities = groupActivitiesByProductName(activities);

    const handleExportCSV = () => {
        const headers = "Id,Name,Group,Leader\n";
        const csvData = activities
            .sort((a, b) => {
                const groupA = a.group_name || a.productName;
                const groupB = b.group_name || b.productName;
                if (groupA === groupB) {
                    return a.adult_full_name.localeCompare(b.adult_full_name);
                }
                return groupA.localeCompare(groupB);
            })
            .map(activity => `${activity.studentId},${activity.studentName} ${activity.studentSurname},${activity.group_name || activity.productName},${activity.adult_full_name}`)
            .join("\n");

        const csvContent = headers + csvData;
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        saveAs(blob, `activities_${formattedDate}_${selectedCampus}.csv`);
    };

    return (
        <section className="garamond">
            <div className="pa2"></div>
            <div className="filter-container" style={{ marginBottom: '20px' }}>
                <div className="date-picker-container" style={{ marginBottom: '20px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                            <Button variant="outlined" onClick={() => changeDate(-1)}>Prev</Button>
                            <DatePicker
                                label="Select Date"
                                value={selectedDate}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <Button variant="outlined" onClick={() => changeDate(1)}>Next</Button>
                        </div>
                    </LocalizationProvider>
                </div>
                <div className="campus-selector" style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <FormControl variant="standard" style={{ minWidth: 120 }}>
                        <InputLabel id="campus-select-label">Campus</InputLabel>
                        <Select
                            labelId="campus-select-label"
                            id="campus-select"
                            value={selectedCampus}
                            onChange={handleCampusChange}
                        >
                            {CAMPUSES.map((campusOption) => (
                                <MenuItem key={campusOption.value} value={campusOption.value}>
                                    {campusOption.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>

            {Object.keys(groupedActivities).map((activityName) => (
                <div key={activityName} style={{ marginBottom: '20px' }}>
                    <h3>{activityName}</h3>
                    <table style={{ width: '80%', textAlign: 'left', margin: '20px auto', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th>Line</th>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Nationality</th>
                                <th>Group</th>
                                <th>Leader</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupedActivities[activityName].map((participant, index) => {
                                const isValidGroup = afternoonGroups.some(group => group.groupName === participant.group_name) ||
                                                     excursionGroups.some(group => group.groupName === participant.group_name);
                                const isValidLeader = afternoonGroups.some(group => group.leader && `${group.leader.adultName} ${group.leader.adultSurname}` === participant.adult_full_name) ||
                                                      excursionGroups.some(group => group.leader && `${group.leader.adultName} ${group.leader.adultSurname}` === participant.adult_full_name);

                                return (
                                    <tr key={`${participant.studentId}`}>
                                        <td>{index + 1}</td>
                                        <td>{participant.studentId}</td>
                                        <td>{participant.studentName} {participant.studentSurname}</td>
                                        <td>{participant.studentNationality}</td>
                                        <td>
                                            <Select
                                                value={isValidGroup ? participant.group_name : ''}
                                                onChange={(e) => handleGroupChange(participant.studentId, 'group_name', e.target.value, 'AFTERNOON')}
                                                className="compact-select"
                                            >
                                                <MenuItem value=''>No Group</MenuItem>
                                                {afternoonGroups.map(group => (
                                                    <MenuItem key={group.id} value={group.groupName}>
                                                        {group.groupName}
                                                    </MenuItem>
                                                ))}
                                                {excursionGroups.map(group => (
                                                    <MenuItem key={group.id} value={group.groupName}>
                                                        {group.groupName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </td>
                                        <td>
                                            <Select
                                                value={isValidLeader ? participant.adult_full_name : ''}
                                                onChange={(e) => handleGroupChange(participant.studentId, 'adult_full_name', e.target.value, 'AFTERNOON')}
                                                className="compact-select"
                                            >
                                                <MenuItem value=''>No Leader</MenuItem>
                                                {afternoonGroups.map(group => group.leader && (
                                                    <MenuItem key={group.id} value={`${group.leader.adultName} ${group.leader.adultSurname}`}>
                                                        {`${group.leader.adultName} ${group.leader.adultSurname}`}
                                                    </MenuItem>
                                                ))}
                                                {excursionGroups.map(group => group.leader && (
                                                    <MenuItem key={group.id} value={`${group.leader.adultName} ${group.leader.adultSurname}`}>
                                                        {`${group.leader.adultName} ${group.leader.adultSurname}`}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ))}

            <Button
                variant="contained"
                color="primary"
                onClick={handleSaveChanges}
                className="floating-save-button"
            >
                Save Changes
            </Button>

            <Button
                variant="contained"
                color="secondary"
                onClick={handleExportCSV}
                className="floating-export-button"
            >
                Export CSV
            </Button>
        </section>
    );
}

export default Activities;
