import React, { useState, useCallback, useEffect } from 'react';
import { DndContext } from '@dnd-kit/core';
import { SERVER_URL } from '../constants.js';
import TextField from '@mui/material/TextField';
import Draggable from './Draggable.jsx';
import Droppable from './Droppable.jsx';
import StudentBedroomCard from './StudentBedroomCard';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import './Bedrooms.css';

function Bedrooms() {
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [databaseState, setDatabaseState] = useState({ classrooms: [], students: [] });
    const [displayState, setDisplayState] = useState({ classrooms: [], students: [] });
    const [selectedCampus, setSelectedCampus] = useState('');
    const [selectedGender, setSelectedGender] = useState('');
    const [selectedAge, setSelectedAge] = useState('');
    const today = dayjs();
    const targetDate = dayjs('2024-06-30');
    const initialDate = today.isAfter(targetDate) ? today : targetDate;
    const [selectedDate, setSelectedDate] = useState(initialDate);
    const [formattedDate, setFormattedDate] = useState(initialDate.format('YYYY-MM-DD'));
    const [campuses, setCampuses] = useState([]);
    const [genders, setGenders] = useState([]);
    const [ages, setAges] = useState([]);

    useEffect(() => {
        setFormattedDate(selectedDate.format('YYYY-MM-DD'));
    }, [selectedDate]);

    const fetchStudents = useCallback(() => {
        const token = sessionStorage.getItem('bearer');
        fetch(`${SERVER_URL}api/studentsresidentbynight/${formattedDate}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch');
            }
            if (response.status === 204) {
                return []; // Return an empty array if there's a 204 response
            }
            return response.json();
        })
        .then(data => {
            setStudents(data);
            setFilteredStudents(data);
            setDatabaseState(prevState => ({
                ...prevState,
                students: data
            }));
            setDisplayState(prevState => ({
                ...prevState,
                students: data
            }));
        })
        .catch((err) => {
            console.error(err);
            setStudents([]);
            setFilteredStudents([]);
        });
    }, [formattedDate]);

    const filterGroupsByWeekAndCampus = useCallback((groups, campus) => {
        return groups.filter(group => {
            const matches = group.groupDates.includes(formattedDate);
            return (!campus || group.campus === campus) && matches;
        });
    }, [formattedDate]);

    const fetchGroups = useCallback(async () => {
        const token = sessionStorage.getItem('bearer');
        try {
            const response = await fetch(`${SERVER_URL}api/campgroups/type/ROOM`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const allGroups = await response.json();
    
            const filteredGroups = filterGroupsByWeekAndCampus(allGroups, selectedCampus);
    
            const groupsWithRelevantStudents = filteredGroups.map(group => {
                const relevantStudentIds = group.studentIds.filter(id => 
                    students.some(student => student.studentId === id)
                );
    
                return {
                    ...group,
                    studentIds: relevantStudentIds,
                    students: relevantStudentIds.map(id => students.find(student => student.studentId === id)).filter(student => student)
                };
            });
    
            setDatabaseState(prevState => ({
                ...prevState,
                classrooms: groupsWithRelevantStudents
            }));
            setDisplayState(prevState => ({
                ...prevState,
                classrooms: groupsWithRelevantStudents
            }));
    
        } catch (err) {
            console.error('Failed to fetch groups:', err);
        }
    }, [selectedCampus, students, filterGroupsByWeekAndCampus]);

    useEffect(() => {
        fetchStudents();
    }, [fetchStudents]);

    useEffect(() => {
        const newCampuses = Array.from(new Set(students.map(student => student.stay_campus)));
        const newGenders = Array.from(new Set(students.map(student => student.studentGender)));
        const newAges = Array.from(new Set(students.map(student => student.age)));

        setCampuses(newCampuses);
        setGenders(newGenders);
        setAges(newAges);

        if (newCampuses.length > 0 && selectedCampus === '') {
            setSelectedCampus(newCampuses[0]);
        }
    }, [students]);

    useEffect(() => {
        if (selectedCampus) {
            fetchGroups();
        }
    }, [selectedCampus, fetchGroups]);

    const handleCampusChange = (event) => {
        setSelectedCampus(event.target.value);
    };

    const filterStudents = useCallback(() => {
        let filtered = students;

        if (selectedCampus) {
            filtered = filtered.filter(student => student.stay_campus === selectedCampus);
        }

        if (selectedGender) {
            filtered = filtered.filter(student => student.studentGender === selectedGender);
        }

        if (selectedAge) {
            filtered = filtered.filter(student => student.age === parseInt(selectedAge, 10));
        }
        
        setFilteredStudents(filtered);
    }, [students, selectedCampus, selectedGender, selectedAge]);

    useEffect(() => {
        if(students.length > 0) {
            filterStudents();
        }
    }, [students, selectedCampus, selectedGender, selectedAge, filterStudents]);

    const handleGenderChange = (event) => {
        setSelectedGender(event.target.value);
    };

    const handleAgeChange = (event) => {
        setSelectedAge(event.target.value);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (!over) return;

        const draggedId = parseInt(active.id);
        const fromClassroom = displayState.classrooms.find(room => room.students.some(s => s && s.studentId === draggedId));
        const toClassroom = over.id === "unassigned" ? null : displayState.classrooms.find(room => room.id === over.id);

        if (fromClassroom === toClassroom) {
            return;
        }

        let updatedClassrooms = [...displayState.classrooms];

        if (fromClassroom) {
            updatedClassrooms = updatedClassrooms.map(room => {
                if (room.id === fromClassroom.id) {
                    return { ...room, students: room.students.filter(s => s && s.studentId !== draggedId) };
                }
                return room;
            });
        }

        if (toClassroom) {
            updatedClassrooms = updatedClassrooms.map(room => {
                if (room.id === toClassroom.id) {
                    const student = students.find(s => s && s.studentId === draggedId);
                    return { ...room, students: [...room.students, student] };
                }
                return room;
            });
        }

        setDisplayState(prevState => ({
            ...prevState,
            classrooms: updatedClassrooms
        }));
    };

    const updateRoomStudents = () => {
        const token = sessionStorage.getItem('bearer');
        const addRequests = [];
        const deleteRequests = [];

        displayState.classrooms.forEach(classroom => {
            const databaseClassroom = databaseState.classrooms.find(ic => ic.id === classroom.id);
            const databaseStudentIds = databaseClassroom.studentIds;
            const currentStudentIds = classroom.students.map(s => s.studentId);

            const studentsToAdd = currentStudentIds.filter(id => !databaseStudentIds.includes(id));
            const studentsToRemove = databaseStudentIds.filter(id => !currentStudentIds.includes(id));

            studentsToAdd.forEach(studentId => {
                addRequests.push(fetch(`${SERVER_URL}api/campgroup/${classroom.id}/student/${studentId}`, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }));
            });

            studentsToRemove.forEach(studentId => {
                deleteRequests.push(fetch(`${SERVER_URL}api/campgroup/${classroom.id}/student/${studentId}`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }));
            });
        });

        Promise.all([...addRequests, ...deleteRequests])
            .then(() => {
                console.log('All operations completed');
                fetchStudents();
                fetchGroups();
            })
            .catch(err => console.error('Error with updating students:', err));
    };

    const changeDate = (increment) => {
        setSelectedDate(prevDate => dayjs(prevDate).add(increment, 'day'));
    };
      
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <DndContext onDragEnd={handleDragEnd}>
            <div className="classrooms-container">
                <div className="filter-container">
                    <div className="filter">
                        <label htmlFor="campus-select">Select Campus: </label>
                        <select id="campus-select" value={selectedCampus} onChange={handleCampusChange}>
                            {campuses.map(campus => (
                                <option key={campus} value={campus}>
                                    {campus}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="filter">
                        <label htmlFor="gender-select">Select Gender: </label>
                        <select id="gender-select" value={selectedGender} onChange={handleGenderChange}>
                            <option value="">Student Gender</option>
                            {genders.map(gender => (
                                <option key={gender} value={gender}>
                                    {gender}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="filter">
                        <label htmlFor="age-select">Select Age: </label>
                        <select id="age-select" value={selectedAge} onChange={handleAgeChange}>
                        <option value="">Any Age</option>
                            {ages.map(age => (
                                <option key={age} value={age}>
                                    {age}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="date-picker-container">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
                            <Button variant="outlined" onClick={() => changeDate(-1)}>Prev</Button>
                            <DatePicker
                                label="Select Date"
                                value={selectedDate}
                                onChange={handleDateChange}
                                slotProps={{selectedDate}}
                            />
                            <Button variant="outlined" onClick={() => changeDate(1)}>Next</Button>
                        </div>
                    </LocalizationProvider>
                </div>
                </div>

                <Droppable key="unassigned" id="unassigned" className="classroom unassigned-students">
                    <div className="droppable-area">
                        <h3>Unassigned Students</h3>
                        {filteredStudents.filter(student =>
                            !displayState.classrooms.some(room => room.students.some(s => s && s.studentId === student.studentId))
                        )
                        .sort((a, b) => a.studentName.localeCompare(b.studentName))
                        .map(student => (
                            <Draggable key={student.studentId} id={student.studentId.toString()}>
                                <StudentBedroomCard person={student} />
                            </Draggable>
                        ))}
                    </div>
                </Droppable>

                <div className="spacer"></div>
                <div className="button-container">
                    <button onClick={updateRoomStudents}>Commit Changes to Database</button>
                </div>
                <div className="spacer"></div>

                {displayState.classrooms.map((classroom) => {
                    const isFull = Number(classroom.students.length) === Number(classroom.capacity);
                    console.log(`Classroom: ${classroom.groupName}, Capacity: ${classroom.capacity}, Students: ${classroom.students.length}, Is Full: ${isFull}`);
                    return (
                        <Droppable 
                            key={classroom.id} 
                            id={classroom.id} 
                            className={`classroom ${isFull ? 'classroom-full' : ''}`}
                        >
                            <div className="droppable-area">
                            <h4>{classroom.groupName} - {isFull ? 'FULL' : `Capacity ${classroom.capacity}`}</h4>
                                {classroom.students
                                .sort((a, b) => a.studentName.localeCompare(b.studentName))
                                .map(student => (
                                    <Draggable key={student.studentId} id={student.studentId.toString()}>
                                        <StudentBedroomCard person={student} />
                                    </Draggable>
                                ))}
                                {classroom.students.length === 0 && <p>No students assigned</p>}
                            </div>
                        </Droppable>
                    );
                })}

            </div>
        </DndContext>
    );
}

export default Bedrooms;
